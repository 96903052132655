import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.div`
  display: none;

  position: fixed;
  right: 0;
  padding-right: 7rem;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: auto;
  flex-flow: column;
  z-index: 100;

  ul {
    display: flex;
    flex-flow: column;
    list-style: none;
    padding: 0;
    text-align: right;

    li {
      height: 2.5em;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        color: ${props => props.theme.colors.subtitle};
        transition: all .3s ease-out;

        &.active {
          font-size: 1.25em;
          color: ${props => props.theme.colors.bodyTextDark};

          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 4rem;
            height: 1px;
            background-color: ${props => props.theme.colors.separator[700]};
            top: 50%;
            right: -7rem;
          }
        }
      }

      &:hover a {
        color: ${props => props.theme.colors.bodyTextDark};
      }
    }
  }


  ${up('tablet')} {
    display: flex;
  }

`

export default Wrapper
