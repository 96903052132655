import React, { useState } from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import H2 from 'src/components/Typography/H2'
import H5 from 'src/components/Typography/H5'
import ModalWindow from 'src/components/ModalWindow'
import Carousel from 'src/components/Carousel'
import Wrapper from './style'

interface Image {
  caption: string
  src: string
}

interface Props {
  images: Image[]
}

const ArchivePhotos = (props:Props) => {
  const { images } = props
  const [modalWindowIsOpen, setModalWindowOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState<number>(0)

  const handleModalWindowOpen = (index:number) => {
    setSelectedImage(index)
    setModalWindowOpen(true)
  }

  const handleCloseModalWindow = () => {
    setModalWindowOpen(false)
  }

  if (images.length < 7) {
    return (
      <Wrapper>
        <Grid xsColumnGap={0} lgColumnGap={20}>
          <Row xsMarginBottom={30} lgMarginBottom={80}>
            <Col xs={12} lg={2} lgOffset={1}>
              <H2>Фотоотчет</H2>
            </Col>
          </Row>
          <Row xsMarginBottom={0}>
            <Col xs={12} lg={9} lgOffset={1} className="grid-column photos inline">
              {images.map((image, index) => (
                <div key={image.src} className="photo-column small-gallery">
                  <div className="photo image-zoom" onClick={() => handleModalWindowOpen(index)}>
                    <img src={image.src} alt={image.caption} />
                  </div>
                </div>
              ))}
            </Col>
          </Row>
        </Grid>
        <ModalWindow isOpen={modalWindowIsOpen} onClose={handleCloseModalWindow}>
          <Carousel simple startIndex={selectedImage} images={images} />
        </ModalWindow>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={30} lgMarginBottom={80}>
          <Col xs={12} lg={2} lgOffset={1}>
            <H2>Фотоотчет</H2>
          </Col>
        </Row>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={9} lgOffset={1} className="grid-column photos">
            <div className="desktop">
              <div className="photo-column">
                <div className="photo image-zoom" onClick={() => handleModalWindowOpen(0)}>
                  <img src={images[1].src} alt={images[0].caption} />
                </div>
                <div className="photo image-zoom" onClick={() => handleModalWindowOpen(1)}>
                  <img src={images[2].src} alt={images[1].caption} />
                </div>
                <div className="photo image-zoom" onClick={() => handleModalWindowOpen(2)}>
                  <img src={images[3].src} alt={images[2].caption} />
                </div>
              </div>
              <div className="photo-column">
                <div className="photo large image-zoom" onClick={() => handleModalWindowOpen(3)}>
                  <img src={images[0].src} alt={images[3].caption} />
                </div>
                <div className="photo small image-zoom" onClick={() => handleModalWindowOpen(4)}>
                  <img src={images[4].src} alt={images[4].caption} />
                </div>
                <div className="photo small image-zoom" onClick={() => handleModalWindowOpen(5)}>
                  <img src={images[5].src} alt={images[5].caption} />
                </div>
              </div>
              <div className="photo-column">
                <div className="photo medium image-zoom" onClick={() => handleModalWindowOpen(6)}>
                  <img src={images[6].src} alt={images[6].caption} />
                </div>
                <div className="photo button interactive" onClick={() => handleModalWindowOpen(0)}>
                  <div>
                    <H5 italic>{images.length}</H5>
                    <H5 italic>фото</H5>
                    <span className="arrow"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile">
              <Carousel
                simple
                startIndex={selectedImage}
                images={images.slice(0,7)}
                onSlideClick={handleModalWindowOpen}
              />
              <div className="photo button" onClick={() => handleModalWindowOpen(0)}>
                <div>
                  <H5 italic>{images.length}</H5>
                  <H5 italic>фото</H5>
                  <span className="arrow"></span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>
      <ModalWindow isOpen={modalWindowIsOpen} onClose={handleCloseModalWindow}>
        <Carousel simple startIndex={selectedImage} images={images} />
      </ModalWindow>
    </Wrapper>
  )
}

export default ArchivePhotos
