import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import H2 from 'src/components/Typography/H2'
import H5 from 'src/components/Typography/H5'
import QuoteGroup from 'src/components/QuoteGroup'
import MediaUrl from 'src/components/MediaUrl'
import Wrapper from './style'

interface Quote {
  text: string
  author: {
    name: string
    about: string
    avatar: string
  }
}

interface URL {
  title: string
  url: string
}

interface Props {
  quotes?: Quote[]
  urls?: URL[]
  text?: string
}

const ArchiveMedia = (props:Props) => {
  const { quotes, urls, text } = props

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={40} lgMarginBottom={80}>
          <Col xs={12} lg={4} lgOffset={1}>
            <H2>О СОБЫТИИ</H2>
          </Col>
        </Row>
        <Row xsMarginBottom={0} lgMarginBottom={80} className="grid-row quotes">
          <Col xs={12} lg={4} lgOffset={1}>
            {text && (<H5>{text}</H5>)}
          </Col>
          <Col xs={12} lg={4} lgOffset={1}>
            {quotes && quotes.map( (quote, index) => {
              return <QuoteGroup key={index} {...quote} />
            })}
          </Col>
        </Row>
        {urls && (
          <Row xsMarginBottom={0}>
            <Col xs={12} lg={9} lgOffset={1} className="grid-column media-urls">
              <div>
                {urls.map( url => <MediaUrl key={url.title} url={url} />)}
              </div>
            </Col>
          </Row>
        )}
      </Grid>
    </Wrapper>
  )
}

export default ArchiveMedia
