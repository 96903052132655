import React from 'react'
import ProgressBar from 'src/components/ProgressBar'
import Navbar from 'src/sections/Navbar'
import Breadcrumbs from 'src/sections/Breadcrumbs'
import Layout from 'src/layout'
import SEO from 'src/components/SEO'
import ExpositionLocation from 'src/sections/ExpositionLocation'
import ArchiveHeader from 'src/sections/ArchiveHeader'
import LocationsNavigation from 'src/components/LocationsNavigation'
import ExpositionVideo from 'src/sections/ExpositionVideo'
import ArchivePhotos from 'src/sections/ArchivePhotos'
import ArchiveMedia from 'src/sections/ArchiveMedia'
import removeHTMLTags from 'src/utils/removeHTMLTags'
import createDateComponent from 'src/utils/createDateComponent'

const createArchive = (archive: any) => {
  const exposition = archive.acf.exposition

  const title = removeHTMLTags(exposition.title)
  const subtitle = removeHTMLTags(exposition.acf.authors)
  const caption = removeHTMLTags(exposition.acf.about)
  const poster = archive?.better_featured_image?.source_url || exposition.acf.poster.source_url

  const slug = archive.slug

  let videoGroup:any = []

  if (archive.acf.video_group) {
    archive.acf.video_group.forEach((video:any) => {
      video && video.url && videoGroup.push({
        src: video.url.url,
        caption: video.url.title
      })
    })
  }

  const video = archive.acf.url && {
    src: archive.acf.url.url,
    caption: archive.acf.url.title
  }

  const photos = (archive.acf.photo ?? []).map((photo: any) => ({
    src: photo.source_url,
    caption: photo.description
  }))

  const about = archive.acf.text

  const quotes = archive.acf.quotes.map((quote: any) => {
    return {
      text: quote.text,
      author: {
        name: quote.author.node.title,
        about: quote.author.node.acf.info,
        avatar: quote.author.node.better_featured_image.source_url
      }
    }
  })


  const mediaURLs = archive.acf?.urls
    ?.map((url: any) => url.url)
    .filter(Boolean)
    .map((url: any) => ({
      url: url.url,
      title: url.title
    }))

  const location = {
    location: archive.acf.city,
    slug: archive.slug,
    date: {
      date_start: createDateComponent(archive.acf.date.start),
      date_end: createDateComponent(archive.acf.date.end)
    }
  }

  const locations = archive.acf.locations.map((location: any) => ({
    location: location.location,
    slug: location.slug,
    date: {
      date_start: createDateComponent(location.date.start),
      date_end: createDateComponent(location.date.end)
    }
  }))

  return {
    exposition: {
      slug: exposition.slug,
      title,
      subtitle,
      caption,
      poster
    },
    slug,
    photos,
    about,
    mediaURLs,
    quotes,
    video,
    videoGroup,
    location,
    locations
  }
}

const AnnouncementPage = (props: any) => {
  const archive = createArchive(props.pageContext.archive)
  const exposition = archive.exposition

  const displayMedia = archive.about
    || archive.quotes.length > 0
    || archive.mediaURLs.length > 0

  let videoContent = []

  if (archive.videoGroup && archive.videoGroup.length > 0) {
    videoContent = archive.videoGroup
  } else if (archive.video) {
    videoContent.push(archive.video)
  }

  return (
    <Layout>
      <SEO url={props.location.href} />
      <ProgressBar />
      <main>
        <div className="desktop">
          <Navbar />
        </div>
        <LocationsNavigation
          pathname={`/expositions/${exposition.slug}/archives`}
          links={archive.locations}
        />
        <Breadcrumbs
          archive
          url={exposition.slug}
          title={exposition.title}
        />
        <ExpositionLocation archive location={archive.location} />
        <ArchiveHeader
          location={archive.location}
          title={exposition.title}
          subtitle={exposition.subtitle}
          caption={exposition.caption}
          poster={exposition.poster}
        />
        {/* {archive.photos.length < 10 ? null : <ArchivePhotos images={archive.photos} />} */}
        {archive.photos.length > 0 && <ArchivePhotos images={archive.photos} />}
        {
          displayMedia && (
            <ArchiveMedia
              text={archive.about}
              quotes={archive.quotes}
              urls={archive.mediaURLs}
            />
          )
        }

        {videoContent.length > 0 && (
          videoContent.map((video:any) => {
            if (video.src) {
              return (
                <ExpositionVideo
                  key={video.caption}
                  src={video.src}
                  caption={video.caption}
                />
              )
            } else {
              return null
            }
          })
          )
        }
      </main>
    </Layout>
  )
}

export default AnnouncementPage
