import React from 'react'
import NavLink from 'src/components/NavLink'
import Wrapper from './style'
import createShortYear from 'src/utils/createShortYear'
import { join } from 'upath'

interface Location {
  location: string
  slug: string
  date: {
    date_start: {
      day: string
      month: string
      year: string
    },
    date_end: {
      day: string
      month: string
      year: string
    }
  }
}

interface Props {
  pathname: string
  links: Location[]
}

const LocationsNavigation:React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <ul>
        {
          props.links.map(link => {
            const to = join(props.pathname, link.slug)
            const title = `${link.location} ‘${createShortYear(link.date.date_end.year)}`

            return (
              <li key={link.slug}>
                <NavLink to={to}>{title}</NavLink>
              </li>
            )
          })
        }
      </ul>
    </Wrapper>
  )
}

export default LocationsNavigation
