import React from 'react'
import Caption from 'src/components/Typography/Caption'
import Wrapper from './style'

interface Props {
  url: {
    title: string
    url: string
  }
}

const MediaUrl = (props:Props) => {
  const { url } = props

  return (
    <Wrapper>
      <a href={url.url}>
        <Caption>{url.title}</Caption>
      </a>
    </Wrapper>
  )
}

export default MediaUrl
