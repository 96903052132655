import React from 'react'
import LocationGroup from 'src/components/LocationGroup'
import Wrapper from './style'

interface Props {
  location: {
    location: string
    slug: string
    date?: any
  }
  archive?: boolean
}

const ExpositionLocation = (props: Props) => {
  const { location, archive } = props

  return (
    <Wrapper className="desktop">
      <LocationGroup extend archive={archive} display item={location} />
    </Wrapper>
  )
}

export default ExpositionLocation
