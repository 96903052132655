import styled from 'styled-components'
import { up } from 'src/utils/media'

const ArrowExtend = require('src/static/icons/ArrowExtend.svg')

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${props => props.theme.containerWidth.sm};

  & .grid-column {
    display: flex;
    flex-flow: column;
  }


  .inline {
    flex-flow: row!important;
    flex-wrap: wrap!important;
    margin-left: auto;
    margin-right: auto;

    & > .small-gallery {
      width: 50%!important;
    }

    ${up('mobile')} {
      flex-flow: row!important;
      flex-wrap: wrap!important;

      & > .small-gallery {
        width: calc(100% * 3 / 9)!important;
      }
    }

    ${up('tablet')} {
      & > .small-gallery {
        width: calc(100% * 3 / 9)!important;
      }
    }
  }

  & h2 {
    text-transform: uppercase;
  }

  ${up('tablet')} {
    max-width: ${props => props.theme.containerWidth.lg};
    margin-top: 4rem;
    margin-bottom: 8rem;
    text-align: left;
    align-items: flex-start;
  }

  ${up('desktop')} {
    margin-top: 0;
    margin-bottom: 10rem;
  }

  ${up('largeDesktop')} {
    margin-bottom: 21rem;
  }

  .photos > div.desktop {
    flex-flow: row nowrap;
    height: calc(100% / 3);

    ${up('tablet')} {
      display: flex!important;
    }
  }

  .photo-column {
    display: flex;
    flex-flow: row wrap;
    padding: 0 1rem;
    align-items: flex-start;

    &.wide {
      width: 100%!important;
    }

    ${up('largeDesktop')} {
      padding: 0 2rem;
    }

    &:nth-child(1) {
      width: calc(100% * 2 / 9);
    }

    &:nth-child(2) {
      width: calc(100% * 4 / 9);
    }

    &:nth-child(3) {
      width: calc(100% * 3 / 9);
    }

    & > .photo {
      display: flex;
      width: 100%;
      padding-top: 100%;
      position: relative;
      margin-bottom: 2rem;
      margin-top: 0;
      overflow: hidden;
      cursor: pointer;

      ${up('largeDesktop')} {
        margin-bottom: 4rem;
      }

      &.small {
        width: calc((100% - 2rem) / 2);
        padding-top: calc((100% - 2rem) / 2);
        margin-right: 2rem;

        &:nth-last-child(1) {
          margin-right: 0;
        }

        ${up('largeDesktop')} {
          width: calc((100% - 4rem) / 2);
          padding-top: calc((100% - 4rem) / 2);
          margin-right: 4rem;
        }
      }

      & > img {
        display: flex;
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: all .8s ease-out;
        object-fit: cover;
      }

      &:hover > img {
        transform: translate(-50%,-50%) scale(1.05);
      }
    }

    .photo.button {
      display: flex;
      flex-flow: column;
      width: 100%;
      position: relative;
      margin-bottom: 2rem;
      margin-top: 0;
      overflow: hidden;

      ${up('largeDesktop')} {
        margin-bottom: 4rem;
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: -1;
        border: 1px solid ${props => props.theme.colors.separator[300]};
        transition: all .8s ease-out;
      }

      & > div {
        position: absolute;
        display: flex;
        flex-flow: column;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        align-items: center;

        & > h5:nth-child(1) {
          font-size: 4em;
          margin-bottom: .2em;

          ${up('desktop')} {
            font-size: 6em;
          }

          ${up('largeDesktop')} {
            font-size: 10em;
          }
        }

        span.arrow {
          display: flex;
          width: 6rem;
          height: 1.5rem;
          margin: 2rem auto 0 auto;
          background: url(${ArrowExtend});
          background-position: right bottom;
          background-repeat: no-repeat;
          background-size: cover;
          transition: all .5s ease-out;

          ${up('desktop')} {
            margin-top: 7rem;
          }

          ${up('largeDesktop')} {
            width: 15rem;
            height: 3rem;
            margin-top: 10rem;
          }
        }
      }

      &:hover {
        &::after {
          width: 88%;
          height: 88%;
        }

        span.arrow {
          width: 8rem;

          ${up('largeDesktop')} {
            width: 20rem;
          }
        }
      }

    }
  }

  .mobile {
    & > div {
      height: auto;
    }

    .image-gallery-index {
      display: none;
    }

    .image-gallery-slides {
      overflow: visible;
    }

    .image-gallery-slide  {
      width: 100%;
      padding-top: 100%;
      border: 8px solid ${props => props.theme.colors.backgroundLight};

      & > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;

        & > img {
          display: flex;
          position: absolute;
          width: 100%;
          height: 100%;
          min-width: 100%;
          min-height: 100%;
          object-fit: cover;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }

    .image-gallery-thumbnails-wrapper {
      button.image-gallery-thumbnail {
        width: 1px;
        height: 1px;
        border-color: ${props => props.theme.colors.separator[100]};
        opacity: 1;
        margin: 3rem 1rem 0 1rem;

        &.active {
          border-color: ${props => props.theme.colors.separator[700]};
        }
      }
    }

    .photo.button {
      display: flex;
      flex-flow: row nowrap;
      border: 1px solid ${props => props.theme.colors.separator[300]};
      margin-top: 4rem;
      padding: 2rem;

      & > div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        & > * {
          margin-right: 1rem;
        }

        & > h5:nth-child(1) {
          font-size: 1.7em;
        }

        span.arrow {
          display: flex;
          width: 4rem;
          height: 1.5rem;
          margin-left: 3rem;
          background: url(${ArrowExtend});
          background-position: right bottom;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
`

export default Wrapper
