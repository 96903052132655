import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: ${props => props.theme.containerWidth.sm};
  margin: 4rem auto;

  ${up('mobile')} {
    margin: 2rem auto;

    & > div {
      justify-content: center;
    }
  }

`

export default Wrapper
