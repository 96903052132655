import styled from 'styled-components'
import { up } from 'src/utils/media'

const Arrow = require('src/static/icons/Arrow.svg')

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 4rem;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  & > a {
    max-width: 20rem;
    margin: 0 auto;

    & > p {
      position: relative;
      line-height: 1.5;
      text-align: center;
      display: block;

      &::after {
        content: '';
        position: relative;
        margin: 2rem auto 0 auto;
        width: 3rem;
        height: 1.3rem;
        display: block;
        background: url(${Arrow});
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

  }

  ${up('mobile')} {
    width: 50%;
  }

  ${up('tablet')} {
    width: 33%;
    margin-bottom: 4rem;

    & > a {
      margin: 0;

      & > p {
        display: inline-block;
        text-align: left;

        &::after {
          display: inline-block;
          position: absolute;
          bottom: .4rem;
          margin: 0 0 0 1rem;
          transition: all .8s ease-out;
        }
      }
      
      &:hover > p::after {
        margin-left: 2rem;
      }
    }
  }

  ${up('desktop')} {
    margin-bottom: 7rem;
  }

  ${up('largeDesktop')} {
    margin-bottom: 10rem;

    & > a {
      max-width: 30rem;
    }
  }
`

export default Wrapper
