import styled from 'styled-components'
import { up, down } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${props => props.theme.containerWidth.sm};

  h5 {
    white-space: pre-line;
  }

  & .grid-column {
    display: flex;
    flex-flow: column;
  }

  & h2 {
    text-transform: uppercase;
  }

  ${down('desktop')} {
    .quotes > .grid-column:nth-child(1) > *:nth-child(1) {
      margin-bottom: 5rem;
    }
  }  

  .media-urls {
    & > div {
      display: flex;
      flex-flow: row wrap;
    }
  }

  ${up('tablet')} {
    max-width: ${props => props.theme.containerWidth.lg};
    margin-top: 4rem;
    margin-bottom: 6rem;
    text-align: left;
    align-items: flex-start;

    .quotes > .grid-column > * {
      margin-bottom: 10rem;
      
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

  ${up('desktop')} {
    margin-top: 0;
    margin-bottom: 5rem;

    .quotes > .grid-column > * {
      margin-bottom: 12rem;
    }
  }

  ${up('largeDesktop')} {
    margin-bottom: 15rem;

    .quotes {
      margin-bottom: 25rem;
    }

    .quotes > .grid-column > * {
      margin-bottom: 25rem;
    }
  }
`

export default Wrapper
