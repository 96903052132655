import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.div`
  & > span {
    display: flex;
  }

  .author {
    margin-top: 4rem;
    margin-bottom: 5rem;
    padding: 0 1rem;

    & > .photo {
      display: flex;
      flex-shrink: 0;
      width: 5.6rem;
      height: 5.6rem;
      border-radius: 50%;
      margin-bottom: 2.5rem;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;

      & > img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }

    & > .about {
      width: 70%;
      text-align: center;
      margin: 0 auto;
    }
  }

  ${up('tablet')} {
    .quote {
      padding-left: 5rem;
      padding-right: 8rem;
    }

    .author {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      padding: 0 0 0 8rem;

      & > .photo {
        margin: 0 2.4rem 0 0;
      }

      & > .about {
        width: 100%;
        text-align: left;
        margin: 0;
      }
    }
  }

  ${up('desktop')} {
    .quote {
      padding-left: 7rem;
      padding-right: 12rem;
    }
  }

`

export default Wrapper
