import React from 'react'
import Subtitle from 'src/components/Typography/Subtitle'
import Quote from 'src/components/Typography/Quote'
import Wrapper from './style'

interface Props {
  text: string
  author: {
    name: string
    about: string
    avatar: string
  }
}

const QuoteGroup = (props:Props) => {
  const { text, author } = props

  return (
    <Wrapper>
      <Quote>{text}</Quote>
      <div className="author">
        <div className="photo">
          <img src={author.avatar} alt="Avatar"/>
        </div>
        <div className="about">
          <Subtitle>{author.name}</Subtitle>
          <Subtitle>{author.about}</Subtitle>
        </div>
      </div>
    </Wrapper>
  )
}

export default QuoteGroup
